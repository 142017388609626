@import './sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';

/* ELEMENT STYLING */
html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background: transparent;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  font-weight: normal !important;
  text-transform: none;
  font-family: $font-page-heading;
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

$primary: $primary-color;

/* BOOTSTRAP OVERRIDES */
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $tertiary-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'white': #ffffff,
  'black': #000000,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'limit-reached': #e03e3e33,
  'not-enough-points': #e03e3e33,
  'add-card': $primary-color,
  'active-card': $primary-color,
  'inactive-card': $disabled-color,
  'frozen-card': $frozen-color,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

/* Form Colors */

.form-control {
  color: #000000;
}

.form-floating > .form-control {
  height: 53px !important;
  min-height: 53px !important;
}

/* eCash Styling */

@media (min-width: 1200px) {
  .topUpModal .modal-dialog {
    max-width: 1140px;
  }
}

/* Banner */

.banner {
  background-color: $black;
  background-size: cover !important;
  justify-content: left;
  background: url('./assets/images/banners/mobile.jpg');
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  @media (min-width: 991px) {
    background: url('./assets/images/banners/desktop.jpg');
  }

  h1 {
    color: $black;
    text-transform: uppercase;
  }
}

.featured-rewards-heading,
.badge-carousel-subheading,
.announcements-main-title,
.badge-subheading,
.rewards-wallet-redeem-title {
  text-transform: uppercase;
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner-v2.jpg);
  background-size: cover;
  background-position: center;
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.filter-modal-dialog {
  display: flex;
  align-items: flex-end !important;
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;

    svg {
      height: 75px;
    }

    &.material-icons-two-tone {
      font-size: 4rem;
      margin: 18px auto 0;
    }
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-color;
    height: 50px;
    @media (max-width: 991px) {
      margin: 0px;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.increment-button,
.decrement-button {
  svg {
    height: 13px;
    width: 13px;
  }
}

.svg-marketplace-details-icons {
  svg {
    height: 14px;
    width: 14px;
  }
}

fgbcl-terms-and-conditions {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-default;
    color: $primary-color;
  }

  h6 {
    font-size: 14px;
    font-weight: bold !important;
  }

  p {
    font-size: 12px;
  }
}

fgbcl-registration-terms-and-conditions {
  h5 {
    font-size: 20px !important;
    font-family: $font-page-heading !important;
  }

  .terms-section-box {
    padding: 0 !important;
  }
}

h6 {
  font-weight: bold !important;
}

fgbcl-card-token-list {
  hr {
    display: none;
  }
}

.marketplace-wallet-download-btn {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.nav.nav-secondary {
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;

    &:not(.active) {
      .icon,
      label {
        color: $info-color !important;
      }
    }
  }
}

.reward-confirm-modal {
  @media (max-width: 991px) {
    .modal-body {
      margin-bottom: 70px;
    }
  }
}

.announcements-carousel {
  .owl-item {
    @media only screen and (min-width: 992px) {
      margin-left: 6px !important;
      margin-right: 2px !important;
      width: 944px !important;
    }
  }
  .owl-carousel .owl-stage-outer {
    padding-left: 0px !important;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

.register-panel {
  > *:not(router-outlet) {
    display: flex;
    flex-direction: column;
    height: inherit;
  }
}

.navbar-nav .nav-link {
  font-family: $font-heading;
}

.help-dropdown .dropdown-menu .nav-link {
  font-family: $font-default;
  font-weight: 400 !important;
}

.challenges-card {
  background-image: url('assets/images/member-card-bg.jpg');
}

.fw-bold:not(h1, h2, h3, h4, h5, h6, .material-icons),
.scorecard-name,
.counter-number-scorecard,
.scorecard-locked-name,
.scorecard-milestones-prize-label,
.scorecard-punchcard-prize-label,
.scorecard-description-modal-completed-text,
.scorecard-description-modal-action-completed,
.scorecard-description-modal-next-challenge-label,
.scorecard-punch-text-model,
.add-card-label,
.wallet-item-purchased-subheading,
.scorecard-description-modal-child-name,
.badge-title,
#points-value,
.purchase-title,
#merchant-value,
.rewards-item-panel-value,
.marketplace-wallet-item-name,
.scorecard-description-modal-challenge-locked-box-text,
.scorecard-description-modal-prize-v2,
.fw-bold:not(.material-icons),
.language-heading,
.announcements-title,
label:not(.card-status, .text-danger, .form-check-label),
.purchase-confirmation-title,
.purchase-confirmation-name,
.btn-confirm,
.btn-cancel,
.btn-purchase-confirm,
.btn-purchase-cancel,
.quantity-value,
.scorecard-description-modal-more-details,
#scorecard-desc-terms .accordion-button[aria-expanded='true'],
.confirmation-attribute-value,
.contact-us-send-button,
.btn-primary {
  font-family: $font-heading !important;
}

.language-item.selected {
  label .language-code {
    font-weight: 400 !important;
    color: $primary-color;
  }
}

.language-dropdown .language-item.selected .language-code {
  font-weight: normal !important;
}

.language-dropdown .language-dropdown-btn span {
  color: $primary-color;
}

fgbcl-badge-overview-list,
fgbcl-scorecard-list-view {
  .my-5 {
    margin-top: 30px !important;
  }
}

.language-select-v2-offcanvas {
  .action-buttons {
    padding-bottom: 0;
  }
}

.quantity-value {
  font-size: 14px;
}

.language-item:not(.selected) {
  label {
    font-family: $font-default;
  }
}

@media (min-width: 992px) {
  .mb-lg-5.rewards-confirm-divider {
    margin-bottom: 0 !important;
  }
}

.form-floating label {
  font-family: $font-default !important;
}

fgb-mobile-nav-menu {
  hr {
    border-top: none !important;
  }
}

.material-symbols-outlined {
  font-size: 24px !important;
}

.scorecard-punchcard-prize-label,
.scorecard-milestones-prize-label {
  margin-right: 5px;
}

.home-member-card {
  background-image: url('assets/images/member-card.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Language */

#languagedropdown {
  background-color: $white;
}

.registration-payment-modal {
  .registration-payment-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: $primary-color;
    color: #ffffff;

    .registration-payment-modal-title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
      font-family: 'Material Icons Outlined';
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
  }

  .payment-form {
    max-width: 310px;
  }
}

fgbcl-top-up-address {
  input,
  select {
    font-size: 14px !important;
  }
}

@media (min-width: 992px) {
  .btn.add-card .icon {
    font-size: 24px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .add-card-label {
    margin-top: 10px !important;
    line-height: 14px;
  }
}

.add-card-label {
  text-transform: capitalize !important;
}

.registration-personal-details .button-container {
  width: 100% !important;
}

@media (max-width: 991px) {
  .add-card {
    min-height: 34px;
  }

  .step-container {
    height: inherit !important;
  }
  .nav.nav-secondary .nav-item {
    flex: 1;
  }
}

#scorecard-actions-completed-number {
  font-size: 30px !important;
}

fgbcl-card-token-list {
  width: 100%;
  max-width: 458px;
}

fgbcl-registration-personal-details {
  h5 {
    padding: 0 10px;
  }

  .button-container {
    padding: 10px;
  }

  .alert.alert-danger {
    margin-left: 15px !important;
  }
}
fgb-registration-layout-page {
  h5 {
    margin-top: 30px;
    font-family: $font-page-heading !important;
    color: $black !important;
    margin-bottom: 20px !important;
  }
}

.payment-cards-title {
  margin: 30px 0 20px 0;
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

fgbcl-notification-hub {
  .pagination {
    min-height: 40px;
  }
}

.notification-links span,
.announcement-link {
  color: $primary-color;
}

.featured-rewards-text,
.scorecard-featured-link {
  font-size: 12px;
}

fgbcl-featured-rewards {
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0 !important;
  }
}

.portal-product-checkout-modal {
  --bs-modal-width: 656px;
  width: 100%;
}

/** Popup home screen shortcut */

.popup-add-to-home-screen-ios-modal-window {
  &.modal {
    height: fit-content;
    top: unset;
    bottom: 0px;
  }
}

.popup-add-to-home-screen-android-modal-window {
  &.modal {
    .modal-dialog {
      width: 290px;
      float: right;
    }
  }
}

.popup-add-to-home-screen-dialog {
  .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

/** Games Page */
.games {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }

          .survey-container {
            &.bg-poll {
              @media (max-width: 991px) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

/** FAQs **/

.faq-section-card-title {
  font-size: 14px !important;
  font-family: $font-default !important;
  font-weight: bold;
}

.faq-section-card-description {
  font-size: 14px !important;
}

fgbcl-countdown {
  .countdown-timer {
    font-size: 14px;
  }
}

.rewards-item-panel-value:not(#ecash-value) {
  visibility: hidden;
}

.rewards-item-details:not(#merchant-value),
.lotto-modal-points-value,
#points-value,
.marketplace-wallet-points-values.d-flex {
  display: none !important;
}

fgbcl-lottos-wallet-details {
  .wallet-divider,
  .lotto-wallet-points-values {
    display: none !important;
  }
}

fgbcl-lottos-list-item {
  .lotto-state-timer {
    display: none !important;
  }
}

fgbcl-lotto-details {
  .countdown-color {
    display: none !important;
  }
}

.item-state,
.rewards-item-panel-status,
.number-entries,
.rewards-item-description-title,
.btn-next-step,
.btn-previous-step {
  font-family: $font-heading !important;
}

// css Hacks

fgbcl-marketplace-actions {
  :has(#purchase-now-button:disabled) {
    fgbcl-number-input {
      #amountInput {
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }
}

.short-long > .btn-toggle {
  font-family: $font-heading;
}

@import 'bootstrap/scss/bootstrap';

/* Requiring Bootstrap */
@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}

/** Payment Provider **/

#paymentPayTechPaymentFrame {
  display: flex;
  flex-direction: column;

  input {
    border: none !important;
  }

  td {
    font-size: 1rem;
  }

  tr {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  #paytechPayButton {
    font-family: $font-heading;
    font-size: 0.875rem;
    background-color: $primary-color;
    color: $white;
    border-radius: 16px !important;
    border: none;
    height: 34px;
    margin-top: 20px;
  }

  div {
    font-size: 0.75rem !important;
    color: $error-color !important;
  }
}

/** Cart Checkout **/

fgbcl-cart-item {
  .col-6.ms-5 {
    display: none;
  }
}

.shadow.rounded.cursor-pointer {
  border-radius: 6px !important;
}

fgb-cart-checkout-page {
  .form-floating {
    margin-bottom: 12px;
    select,
    input {
      font-size: 0.875rem;
      min-height: 54px !important;
    }
  }
}

.font-default {
  font-family: $font-default;
}

.font-page-heading {
  font-family: $font-page-heading;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-heading {
  font-family: $font-heading;
}

.font-size-12 {
  font-size: 12px;
}

.text-error {
  color: $error-color;
}

fgb-survey-item-predictor {
  fgb-survey-item-answer {
    .survey-answer-item.selected .answer-text {
      background-color: $secondary-color;
    }
  }
}

fgb-fantasy-games-page {
  .leaderboard-nav .owl-theme {
    .owl-prev, .owl-next {
      top: 1px !important;
    }
  } 
}